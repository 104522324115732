import React from 'react';
import {
  Datagrid,
  DateField,
  List,
  NumberField,
  TextField,
  Filter,
  NumberInput,
  TextInput,
  BooleanField,
  Toolbar,
  useListContext,
  ResourceComponentProps,
  SelectInput,
} from 'react-admin';

import { ChevronLeft, ChevronRight } from '@material-ui/icons';

import IconField from 'layout/fields/IconField';
import SuccessButton from 'layout/buttons/SuccessButton';
import BooleanFromNumberField from 'layout/fields/BooleanFromNumberField';
import { profileType } from '../field-choices';

const RegularUserFilter = (props: any) => (
  <Filter {...props} style={{ maxWidth: '1000px' }}>
    <NumberInput source="id" label="Id" alwaysOn style={{ width: '120px' }} />
    <TextInput
      source="email"
      alwaysOn
      label="Email"
      style={{ width: '120px' }}
    />
    <TextInput
      source="ulid_id"
      alwaysOn
      label="Ulid"
      style={{ width: '120px' }}
    />
    <TextInput
      source="username"
      alwaysOn
      label="Username"
      style={{ width: '120px' }}
    />
    <NumberInput
      source="site_id"
      label="Site"
      alwaysOn
      style={{ width: '120px' }}
    />
    <SelectInput
      source="profile_type"
      choices={profileType}
      alwaysOn
      style={{ width: '120px' }}
    />
  </Filter>
);

const Pagination = () => {
  const { ids, page, perPage, setPage } = useListContext();
  if (page === 1 && (!ids || ids.length < perPage)) {
    return <></>;
  }
  return (
    <Toolbar>
      {page > 1 && (
        <SuccessButton key="prev" onClick={() => setPage(page - 1)}>
          <ChevronLeft />
          Prev
        </SuccessButton>
      )}
      {ids.length === perPage && (
        <SuccessButton key="next" onClick={() => setPage(page + 1)}>
          Next
          <ChevronRight />
        </SuccessButton>
      )}
    </Toolbar>
  );
};

const RegularUserList = (props: ResourceComponentProps) => {
  return (
    <List
      {...props}
      filters={<RegularUserFilter />}
      perPage={25}
      sort={{ field: 'id', order: 'DESC' }}
      pagination={<Pagination />}
    >
      <Datagrid optimized rowClick="edit">
        <NumberField source="id" />
        <TextField source="ulid_id" sortable={false} />
        <TextField source="email" />
        <NumberField source="site_id" sortable={false} />
        <TextField source="profile_type" sortable={false} />
        <NumberField source="age" sortable={false} />
        <TextField source="name" />
        <BooleanField source="is_deleted" sortable={false} />
        <TextField source="moderation_status" sortable={false} />
        <BooleanField source="is_premium" sortable={false} />
        <BooleanFromNumberField source="is_test" />
        <BooleanField source="is_chb" sortable={false} />
        <NumberField source="credits" sortable={false} />
        <IconField source="thumbnail" label="Thumbnail" sortable={false} />
        <TextField source="photo_count" sortable={false} />
        <TextField source="city" sortable={false} />
        <TextField source="country" sortable={false} />
        <DateField
          source="created_at"
          locales="ru-RU"
          showTime
          sortable={false}
        />
      </Datagrid>
    </List>
  );
};

export default RegularUserList;
