import { FC, useEffect, useState } from 'react';
import { httpClient } from 'httpClient';
import {
  SimpleForm,
  SelectArrayInput,
  required,
  Toolbar,
  TextInput,
  SaveButton,
  NumberInput,
  BooleanInput,
  SelectInput,
  useNotify,
  useRedirect,
} from 'react-admin';
import CircularProgress from '@material-ui/core/CircularProgress';

import { PidvalApi, SiteIdChoices } from 'api/PidvalApi';

import { Supply } from '../../../model-types';

import { supplyRank } from './field-choices';
import { Typography } from '@material-ui/core';

interface SupplyProps {
  record?: Supply;
  isEdit?: boolean;
}

const SupplyForm: FC<SupplyProps> = ({ record, isEdit = false, ...rest }) => {
  const [siteIdChoices, setSiteIdChoices] = useState<SiteIdChoices[]>([]);
  const [prevSiteIdsList, setPrevSiteIdsList] = useState<number[] | undefined>(
    [],
  );
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const notify = useNotify();
  const redirectTo = useRedirect();

  const validateFloatNumber = (value: number) => {
    if (value < 0.01) {
      return 'Value must be greater than 0.01';
    }
    if (value > 1) {
      return 'Value must be less than 1';
    }
  };

  const validateGiftCoefficient = (value: number) => {
    if (value < 0) {
      return 'Value must be greater than 0';
    }
    if (value > 1) {
      return 'Value must be less than 1';
    }
  };

  const handleSave = (data: any) => {
    setIsButtonDisabled(true);

    httpClient
      .put(`/supply/${data.id}`, data, { timeout: 60000 })
      .then((response) => {
        setIsButtonDisabled(false);

        if (!prevSiteIdsList) {
          return;
        }

        if (response.data.data.site_ids.length < prevSiteIdsList?.length) {
          const removedItems = prevSiteIdsList.filter(
            (item) => !response?.data?.data?.site_ids.includes(item),
          );

          const removedMirrorsName = siteIdChoices
            .filter((item) => removedItems.includes(item.id))
            .map((item) => item.name);

          notify(
            `You will deactivate ${
              record?.trusted_users_count
            } active profiles from  ${removedMirrorsName.join(', ')}`,
            'success',
          );

          redirectTo('/supply');

          return;
        }

        const availableMirrors = siteIdChoices
          .filter((item) => response.data.data.site_ids.includes(item.id))
          .map((item) => item.name);

        notify(
          `You will duplicate ${
            record?.trusted_users_count
          } active profiles on ${availableMirrors.join(', ')}`,
          'success',
        );

        redirectTo('/supply');
      })
      .catch((error) => notify(error.message, 'error'));
  };

  useEffect(() => {
    if (!prevSiteIdsList?.length && record?.site_ids) {
      setPrevSiteIdsList(record?.site_ids);
    }
  }, [record?.site_ids, prevSiteIdsList?.length]);

  useEffect(() => {
    const setSiteId = async () => {
      try {
        const siteInfo = await PidvalApi.fetchMirrorShortInfo();
        setSiteIdChoices(siteInfo);
      } catch (error) {
        console.error(error);
      }
    };

    setSiteId();
  }, []);

  return (
    <SimpleForm
      redirect="list"
      record={record}
      toolbar={
        <Toolbar alwaysEnableSaveButton style={{ position: 'relative' }}>
          {isEdit ? (
            <SaveButton onSave={handleSave} disabled={isButtonDisabled} />
          ) : (
            <SaveButton />
          )}
          {isButtonDisabled && (
            <div style={{ position: 'absolute', top: '17px', left: '60px' }}>
              <CircularProgress color="secondary" size="30px" />
            </div>
          )}
        </Toolbar>
      }
      {...rest}
    >
      <Typography variant="h6" gutterBottom>
        General
      </Typography>
      <TextInput source="name" validate={[required()]} />
      {siteIdChoices && (
        <SelectArrayInput
          label="Site ids"
          source="site_ids"
          choices={siteIdChoices}
          validate={[required()]}
        />
      )}
      <SelectInput
        label="Rank"
        source="rank"
        choices={supplyRank}
        defaultValue={0}
        validate={[required()]}
      />
      <NumberInput
        source="coefficient"
        label="Paid credits coefficient"
        min={0.04}
        max={1}
        defaultValue={0.0}
        validate={validateFloatNumber}
      />
      <NumberInput
        label="Free credits coefficient"
        source="free_coefficient"
        min={0.04}
        max={1}
        defaultValue={0.0}
        validate={validateFloatNumber}
      />
      <BooleanInput source="code_available" defaultValue={false} />
      <Typography variant="h6" gutterBottom>
        Gifts
      </Typography>
      <BooleanInput source="gift_available" defaultValue={false} />
      <NumberInput
        source="gift_coefficient"
        min={0}
        max={1}
        defaultValue={0.0}
        validate={validateGiftCoefficient}
      />
    </SimpleForm>
  );
};

export default SupplyForm;
